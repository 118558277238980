'use strict';

module.exports = {
    url: 'https://pastora.jp',
    title: 'PASTORA',
    subtitle: 'PASTORA/パストラはプログラマー・フォトグラファーTsuyoshi Okumura（奥村 健）のポートフォリオサイトです。This site is portfolio of Japan-based Programmer/Photographer Tsuyoshi Okumura.',
    copyright: '© 2019- Tsuyoshi Okumura All rights reserved.',
    disqusShortname: 'pastora',
    postsPerPage: 4,
    googleAnalyticsId: 'UA-136342604-1',
    googleSearchConsoleId: 'M6zgaVHAAAv9BoAKnW3XTzv-BUbTXKJes--nImtemmM',
    facebookAppId: '2168167636552365',
    menu: [
        {
            label: 'Articles',
            path: '/'
        },
        {
            label: 'About me',
            path: '/pages/about'
        },
        {
            label: 'Photo',
            path: '/pages/photo'
        },
        {
            label: 'Contact me',
            path: '/pages/contacts'
        }
    ],
    author: {
        name: 'Tsuyoshi Okumura',
        photo: '/me.jpg',
        bio: '横浜でエンジニアしてます。HTML, CSS, JavaScript, Vue, TypeScript, GatsbyJS',
        contacts: {
            email: 'o.tsuyoshi823@gmail.com',
            twitter: '@t_pastorale',
            github: 'o96ra244',
            rss: 'https://pastora.jp/rss.xml',
        }
    }
};

import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';

export const PureSidebar = ({ data, isIndex }) => {
    const {
        title,
        author,
        copyright,
        menu
    } = data.site.siteMetadata;
    let siteTitle;

    if (isIndex) {
        siteTitle = (
            <h1 className={styles['sidebar__title']}>
                <Link className={styles['sidebar__title--link']} to="/">{title}</Link>
            </h1>
        );
    } else {
        siteTitle = (
            <h3 className={styles['sidebar__title']}>
                <Link className={styles['sidebar__title--link']} to="/">{title}</Link>
            </h3>
        );
    }

    return (
        <div className={styles['sidebar']}>
            <div className={styles['sidebar__inner']}>
                {siteTitle}
                <Author author={author} isIndex={isIndex}/>

                <div className={styles['sidebar__footer']}>
                    <Menu menu={menu}/>
                    <Contacts contacts={author.contacts}/>
                    <Copyright copyright={copyright}/>
                </div>
            </div>
        </div>
    );
};

export const Sidebar = (props) => (
    <StaticQuery query={graphql`
      query SidebarQuery {
        site {
          siteMetadata {
            title
            subtitle
            copyright
            menu {
              label
              path
            }
            author {
              name
              photo
              bio
              contacts {
                twitter
                github
                email
                rss
              }
            }
          }
        }
      }
    `} render={(data) => <PureSidebar {...props} data={data}/>}/>
);

export default Sidebar;

import React from 'react';
import Helmet from 'react-helmet';
import styles from './Layout.module.scss';
import Contacts from '../Sidebar/Contacts';
import Copyright from '../Sidebar/Copyright';
import Menu from '../Sidebar/Menu';

const siteConfig = require('../../../config.js');

const Layout = ({ children, title, description, slug, isIndex }) => (
    <div className={styles.layout}>
        <Helmet>
            <html lang="ja"/>
            <title>{title}</title>
            <meta name="description" content={description}/>

            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:url" content={slug ? siteConfig.url + slug : siteConfig.url}/>
            <meta property="og:type" content={isIndex ? 'website' : 'article'}/>
            <meta property="og:site_name" content={siteConfig.title}/>
            <meta property="og:image" content={`${siteConfig.url}/me.jpg`}/>
            <meta property="fb:app_id" content={siteConfig.facebookAppId}/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content={siteConfig.author.contacts.twitter}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={`${siteConfig.url}/me.jpg`}/>

            <meta name="google-site-verification" content={siteConfig.googleSearchConsoleId} />
        </Helmet>
        {children}

        <div className={styles['layout__footer']}>
            <div className={styles['layout__footer__inner']}>
                <Menu menu={siteConfig.menu}/>
                <Contacts contacts={siteConfig.author.contacts}/>
            </div>
            <Copyright copyright={siteConfig.copyright}/>
        </div>
    </div>
);

export default Layout;
